img {
    width: 40px;
    margin-bottom: 5px; 
}


.expense_date_day {
    font-size: 30px;
    font-weight: bold;
    color: #17a8e3;
}

.expense_date_month {
    font-size: 16px;
    color: #555;
}

.expense_date_year {
    font-size: 16px;
    color: #555;
}