.card {
    /* max-width: 300px; */
    border: 1px solid #e1e1e1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    background-color: #bb9faa;
    margin: 20px;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }