

.expense_input {
    display: flex;
    justify-content: left;
    align-items: center;
    /* height: 100vh; */
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    
}

.expense_form {
    background-color: #fff;
   /* background: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-wallpaper-background_58702-3917.jpg) no-repeat;
   background-size: cover; */
   filter: blur(0.5px);
  
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
}

.expense_form h1 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.input_expense_title,
.input_expense_price,
.input_expense_date {
    width: 90%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    margin-left: 8px;
}

.input_expense_title::placeholder,
.input_expense_price::placeholder,
.input_expense_date::placeholder {
    color: #999;
}

.add_expense {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.add_expense:hover {
    background-color: #0056b3;
}

.left{
    text-align: left;
    margin-left:100px;
    color: white;
}