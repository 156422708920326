.expensefilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgray;
  border: 1px solid #e0e0e0;
  border-radius: 5px 20px 5px;
  cursor: pointer;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 30px;
}

label {
  font-weight: bold;
}

#expensefilter {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.showallbtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.showallbtn:hover {
  background-color: #0056b3;
}