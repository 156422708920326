.expense_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E2E5DE;
    border: 1px solid #e0e0e0;
    border-radius: 5px 20px 5px;
    cursor: pointer;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; 
    
}

.expense_item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


.expenseicon {
    position: absolute;
    top: -15px; 
    right: 10px; 
    opacity: 0; 
    transition: opacity 0.5s ease-in-out;
    
}

.expenseicon i{
    margin-left: 20px;
    font-size: 2.2rem;
}

.expenseicon .edit{
    color: blue;
}

.expenseicon .delete{
    color: red;
}

.expense_item:hover .expenseicon {
    opacity: 1; 
}



.expense_date {
    font-size: 1.3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f0f0f0;
    /* background: url(https://cdn-icons-png.flaticon.com/128/2948/2948239.png) no-repeat;
    background-size: cover; */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    
    min-width: 80px;
}


.expense_title {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 5px;
}


.expense_price {
    font-size: 1.4rem;
    font-weight: bold;
    color: white;
    background-color: #bb9faa;
    padding: 15px;
    border-radius: 5px 20px 5px;
}

.expenseicon{
   
}