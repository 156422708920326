.card {
    border: 1px solid #e1e1e1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    background-color: #bb9faa;
    border-radius: 5px 20px 5px;
    /* background: 	#5d726e; */
   
}

/* navbar.css */

.navbar {
    background-color: 	#bb9faa;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
   
}

.navbar_left {
    font-size: 24px;
    font-weight: bold;
}

.navbar_right {
    display: flex;
    gap: 10px;
}

.add_expense_btn,
.show_expense_btn {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.add_expense_btn:hover,
.show_expense_btn:hover {
    background-color: #fff;
    color: #007bff;
}

.nodata, h1{
    text-align: center;
    color: blueviolet;
    font-size: 2.3rem;
   
}

.seccard{
    padding: 40px;
    margin-left: 15%;
    margin-right: 15%;
    border-radius: 5px 20px 5px;
    background-color: orange;
}

.expenseChart {
    width: 100%; 
    max-width: 50%; 
    margin: 0 auto; 
    padding: 20px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8); 
    background-color: #ffffff; 
    border-radius: 5px; 
  }

  .expenseChartCircle{
    width: 100%; 
    max-width: 28%; 
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px; 
    margin-right: 30px;
    padding: 10px;
    /* padding: 20px;  */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8); 
    background-color: #ffffff; 
    border-radius: 5px; 
  }